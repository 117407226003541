import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import OlImageLayer from 'ol/layer/Image';
import OlVectorSource from 'ol/source/Vector';
import OlLayerGroup from 'ol/layer/Group';
import OlView from 'ol/View';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Zoom from 'ol/control/Zoom';
import ImageWMS from 'ol/source/ImageWMS';
import { ConfigurationService } from '../services/configuration.service';
import { Resource } from '../models/Resource';
import { APIService } from '../services/api.service';
import { GeoTag } from '../models/GeoTag';
import { Tag } from '../models/Tag';
import { ActivatedRoute } from '@angular/router';
import { fromLonLat, transform } from 'ol/proj';
import { ResourceType } from '../models/ResourceType';
import { NgxSmartModalService } from 'ngx-smart-modal';
import LayerSwitcher from 'ol-layerswitcher';
import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import Stamen from 'ol/source/Stamen';
import OSM from 'ol/source/OSM';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import TileWMS from 'ol/source/TileWMS';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { isNumber } from 'util';
import Overlay from 'ol/Overlay';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class MapComponent implements OnInit, AfterViewInit {

    @ViewChild('spatialMap') mapElement: ElementRef;
    public map: OlMap;
    private source: OlXYZ = new OlXYZ({
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: window.location.origin
    });
    private mapLayer: OlTileLayer;
    private markerLayer: OlVectorLayer;
    private markerSource = new OlVectorSource();
    public expanded_types: string[] = [];
    public resources: Array<Resource> = [];
    public selectedGeoTag: GeoTag;
    public wmsResult;
    private headerHeight: number;
    public cont_height = 800;

    constructor(
        private configurationService: ConfigurationService,
        private apiService: APIService,
        private route: ActivatedRoute,
        private ngxSmartModalService: NgxSmartModalService,
        private http: HttpClient,
        private changeDetector: ChangeDetectorRef
    ) { }

    // @HostListener('window:resize', ['$event'])
    resize() {
        // this is pretty hacky
        const internal_element_height = document.querySelector('html').clientHeight;
        const footer_element_height = document.querySelector('footer').clientHeight;
        this.cont_height = (internal_element_height - ((this.headerHeight) + footer_element_height));
    }

    ngOnInit() {

        this.headerHeight = document.querySelector('nav').clientHeight;
        this.resize();

        this.configurationService.setTitle('Maps');

        const markerStyle = new Style({
            image: new Icon(({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 0.75,
                src: '/assets/images/map-pin.png'
            }))
        });

        this.markerLayer = new OlVectorLayer({
            source: this.markerSource,
            style: function (el, resolution) {
                console.log(el, el.getProperties().geoTag);
                return new Style({
                    image: new Icon(({
                        opacity: 1,
                        src: el.getProperties().geoTag.thumbnail_url,
                        scale: 0.1
                    }))
                });
            }
        });

    }

    public viewerJSUrlCast(url: string): string {
        return url.replace(this.configurationService.api_url, this.configurationService.api_url + '/ViewerJS/index.html#');
    }

    public viewerMicrosfotCast(url: string): string {
        return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
    }

    public openModal(resource: Resource): void {
        this.ngxSmartModalService.setModalData(resource, 'resourceModal');
        this.ngxSmartModalService.getModal('resourceModal').open();
    }

    public clearModelData() {
        this.ngxSmartModalService.resetModalData('resourceModal');
    }

    public filterGeoTagResultsByType(type: string): Resource[] {
        return this.apiService.filterResourcesByType(type, this.selectedGeoTag.resources);
    }

    public getGeoTagResourceTypes(): ResourceType[] {
        return this.apiService.getResourceTypes(this.selectedGeoTag.resources);
    }

    public filterResultsByType(typeTitle: string): Array<Resource> {

        return this.resources.filter((resource) => {
            return resource.type.title === typeTitle;
        });
    }

    ngAfterViewInit() {
        let mapLayers = [];
        this.apiService.getResourcesBasic().subscribe((resources: Array<Resource>) => {
            this.resources = resources;
            resources.filter((resource: Resource) => {
                // TODO get this string dynamically or from constant
                return resource.type.title.indexOf('Map resource') > -1;
            }).forEach((resource) => {
                const newLayer = new OlTileLayer({
                    title: resource.title,
                    visible: false,
                    source: new TileWMS({
                        url: 'https://geoserver.epiphron.co.nz:443/wms',
                        params: {
                            'FORMAT': 'image/png',
                            'VERSION': '1.1.1',
                            tiled: true,
                            'LAYERS': resource.title.replace(/ /g, '_').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '_'),
                            'exceptions': 'application/vnd.ogc.se_inimage',
                        },
                        crossOrigin: window.location.origin,
                        serverType: 'geoserver'
                    })
                });
                mapLayers.push(newLayer);
            });

            this.route.params.subscribe(url_params => {
                if (typeof url_params.map_layers !== 'undefined') {

                    mapLayers = [];
                    resources.filter((resource: Resource) => {
                        return resource.type.title.indexOf('Map layer') > -1;
                    }).forEach((resource_arr_id) => {
                        const resource: Resource = this.apiService.getResourceById(Number(resource_arr_id), this.resources);
                        const newLayer = new OlTileLayer({
                            title: resource.title,
                            visible: false,
                            source: new TileWMS({
                                url: 'https://geoserver.epiphron.co.nz:443/wms',
                                params: {
                                    'FORMAT': 'image/png',
                                    'VERSION': '1.1.1',
                                    tiled: true,
                                    'LAYERS': resource.title.replace(/ /g, '_').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '_'),
                                    'exceptions': 'application/vnd.ogc.se_inimage',
                                },
                                crossOrigin: window.location.origin,
                                serverType: 'geoserver'
                            })
                        });
                        mapLayers.push(newLayer);
                    });
                }
            });

            this.apiService.getGeoTagsBasic().subscribe((geoTagList: Array<GeoTag>) => {
                const list = [];
                geoTagList.forEach((geoTag: GeoTag) => {
                    console.log(geoTag);
                    if (typeof geoTag.longitude !== 'undefined' && geoTag.longitude !== null && isNumber(geoTag.longitude)
                        && typeof geoTag.latitude !== 'undefined' && geoTag.latitude !== null && isNumber(geoTag.latitude)) {
                        const point = new Point(transform([geoTag.longitude, geoTag.latitude], 'EPSG:4326', 'EPSG:3857'));
                        if (!isNaN(point.flatCoordinates[0]) && !isNaN(point.flatCoordinates[1])) {
                            const geoIcon = new Feature({
                                geometry: point,
                                geoTag: geoTag
                            });

                            list.push(geoIcon);
                        }
                    }
                });
                console.log(list);
                this.markerSource.addFeatures(list);

                this.changeDetector.detectChanges();
            });

            const markerStyle = new Style({
                image: new Icon(({
                    anchor: [0, 0],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    opacity: 1,
                    src: '/assets/images/big-dot.png',
                    scale: 0.1,
                }))
            });

            this.markerLayer = new OlVectorLayer({
                source: this.markerSource,
                style: markerStyle
            });

            var container = document.getElementById('popup');
            var content = document.getElementById('popup-content');
            var closer = document.getElementById('popup-closer');

            var overlay = new Overlay({
                element: container,
                autoPan: false,
                autoPanAnimation: {
                  duration: 250
                }
              });
        
        
              /**
               * Add a click handler to hide the popup.
               * @return {boolean} Don't follow the href.
               */
              closer.onclick = function() {
                overlay.setPosition(undefined);
                closer.blur();
                return false;
              };

            this.map = new OlMap({
                target: this.mapElement.nativeElement,
                layers: [
                    new OlLayerGroup({
                        // A layer must have a title to appear in the layerswitcher
                        'title': 'Base maps',
                        layers: [
                            this.markerLayer,
                            new OlTileLayer({
                                title: 'LINZ',
                                type: 'base',
                                visible: true,
                                source: this.source
                            }),
                            new OlTileLayer({
                                // A layer must have a title to appear in the layerswitcher
                                title: 'Water color',
                                // Again set this layer as a base layer
                                type: 'base',
                                visible: false,
                                source: new Stamen({
                                    layer: 'watercolor',
                                    crossOrigin: window.location.origin
                                })
                            }),
                            new OlTileLayer({
                                // A layer must have a title to appear in the layerswitcher
                                title: 'OSM',
                                // Again set this layer as a base layer
                                type: 'base',
                                visible: false,
                                source: new OSM({
                                    crossOrigin: window.location.origin
                                })
                            })
                        ]
                    }),
                    new OlLayerGroup({
                        // A layer must have a title to appear in the layerswitcher
                        title: 'GIS Resources',
                        // Adding a 'fold' property set to either 'open' or 'close' makes the group layer
                        // collapsible
                        fold: 'open',
                        layers: mapLayers
                    }),
                    new OlLayerGroup({
                        // A layer must have a title to appear in the layerswitcher
                        title: 'Geospatial Tags',
                        layers: [this.markerLayer]
                    })
                ],
                view: new OlView({
                    center: fromLonLat([176.166672, -37.683334]), // has to be reversed for some obseen reason
                    zoom: 10,
                }),
                renderer: 'canvas',
                controls: [
                    new Zoom(),
                    // new LayerSwitcher()
                ],
                interaction: [],
                overlays: [overlay]
            });
            const layerSwitcher = new LayerSwitcher({
                tipLabel: 'Layer Visibility', // Optional label for button
                groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
            });
            this.map.addControl(layerSwitcher);
            this.map.on('singleclick', (evt) => {

                const feature = this.map.getFeaturesAtPixel(evt.pixel);
                if (feature !== null) {
                    this.apiService.getGeoTag(feature[0].get('geoTag').id).subscribe(full_geoTag => {
                        this.selectedGeoTag = full_geoTag;
                        this.changeDetector.detectChanges();
                    });
                    evt.preventDefault();
                } else {
                    this.selectedGeoTag = undefined;
                    this.wmsResult = undefined;
                    this.map.forEachLayerAtPixel(evt.pixel, (layer) => {
                        console.log(layer.getSource());
                        const view = this.map.getView();
                        const viewResolution = view.getResolution();
                        const source = layer.getSource();
                        const url = source.getGetFeatureInfoUrl(
                            evt.coordinate, viewResolution, view.getProjection(),
                            { 'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': 50 });
                        console.log(url);
                        if (url) {
                            this.http.get(url).pipe(map((response) => {
                                this.wmsResult = response;
                                this.changeDetector.detectChanges();
                            }, (error) => {
                                console.log('GeoServer WMS Error:', error);
                            })).subscribe((response) => {
                                console.log('done');
                            });
                        } else {
                            this.wmsResult = undefined;
                            this.changeDetector.detectChanges();
                        }
                    });
                }
            });
            this.map.on('pointermove', (evt) => {
                if (evt.dragging) {
                    return;
                }

                const pixel = this.map.getEventPixel(evt.originalEvent);
                const hit = this.map.forEachLayerAtPixel(pixel, (layer) => {
                    return (layer.type === 'IMAGE') ? true : false;
                });
                this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';

                overlay.setPosition(undefined);

                this.map.forEachFeatureAtPixel(evt.pixel, function (f) {

                    content.innerHTML = '<strong class="d-block text-center">' + f.getProperties().geoTag.title + '</strong><hr><img class="img-fluid" src="' + f.getProperties().geoTag.thumbnail_url + '" alt="location thumbnail"/>';
                    overlay.setPosition(evt.coordinate);

                    return true;
                });
            });
            this.map.on('rendercomplete', () => {
                this.resize();
                this.map.updateSize();
                // this.changeDetector.detectChanges();
            });
        });

    }

}
