import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import { ConfigurationService } from "./configuration.service";
import { CachedValue } from '../models/CachedValue';
import { Storage } from './storage.service';
import { environment } from '../../../src/environments/environment';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(
        private config: ConfigurationService,
        private storage: Storage
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        // dont cache some requests
        // if (req.url.indexOf(this.config.api.lookup) > -1) {
        //     return next.handle(req);
        // }

        console.log('req.url', req.url);

        // only cache if enabled
        if (!environment.cached) {
            return next.handle(req);
        }

        // pass along non-cacheable
        if (req.method !== "GET") {
            return next.handle(req);
        }

        return from(this.storage.get("cached_" + req.url))
            .pipe(
                switchMap(cachedResponse => {
                    if (cachedResponse === null) {
                        return next.handle(req).pipe(
                            tap((event) => {
                                if (event instanceof HttpResponse) {
                                    this.storage.set(
                                        "cached_" + req.url,
                                        new CachedValue(event.body)
                                    );
                                }
                            })
                        );
                    } else {
                        cachedResponse = new CachedValue(cachedResponse.value, cachedResponse.expirey);
                        if (cachedResponse.isCurrent() || !navigator.onLine)
                        {
                            console.log('this should be returning something itneresting sdioufhgsgoiuhiuerg', cachedResponse.value);

                            // this is not working

                            return of(new HttpResponse({body: cachedResponse.value}));
                        } else {
                            // invalidate, recast and cache the response
                            return from(this.storage.remove("cached_" + req.url))
                                .pipe(
                                    switchMap(() => {
                                        return next.handle(req).pipe(
                                            tap((event) => {
                                                if (event instanceof HttpResponse) {
                                                    this.storage.set(
                                                        "cached_" + req.url,
                                                        new CachedValue(event.body)
                                                    );
                                                }
                                            })
                                        );
                                    })
                                );
                        }
                    }
                })
            );
    }
}
