import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../src/environments/environment';

@Injectable()
export class ConfigurationService {

public api_url = environment.api_url;

  public siteName = environment.title;
  public siteCopyright = environment.copyright;

  constructor(private title: Title) { }

  setTitle(newTitle: string) {
    if (newTitle.length > 0) {
      this.title.setTitle(newTitle);
    } else {
      this.title.setTitle(this.siteName);
    }
  }

}
