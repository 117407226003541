import { LoginComponent } from './../login/login.component';
import { AcknowledgementsComponent } from './../acknowledgements/acknowledgements.component';
import { TermsComponent } from './../terms/terms.component';
import { ContactComponent } from './../contact/contact.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { ResultsComponent } from '../results/results.component';
import { InfoComponent } from '../info/info.component';
import { MapComponent } from '../map/map.component';
import { SplashComponent } from '../splash/splash.component';
import { ExpoComponent } from '../expo/expo.component';
import { CollectionComponent } from '../collection/collection.component';
import { HelpComponent } from '../help/help.component';
import { GeneologyComponent } from '../geneology/geneology.component';
import { ResourceComponent } from '../resource/resource.component';
import { AuthGuard } from '../services/auth.guard';

const routes: Routes = [
    {
        path: 'collection/:collection_id',
        component: CollectionComponent,
        canActivate: []
    },
    {
        path: 'expo/:tag_title',
        component: ExpoComponent,
        canActivate: []
    },
    {
        path: 'about',
        component: InfoComponent,
        canActivate: []
    },
    {
        path: 'results/:tag_ids',
        component: ResultsComponent,
        canActivate: []
    },
    {
        path: 'map',
        component: MapComponent,
        canActivate: []
    },
    {
        path: 'map/geo_tags/:geo_tags',
        component: MapComponent,
        canActivate: []
    },
    {
        path: 'map/map_layers/:map_layers',
        component: MapComponent,
        canActivate: []
    },
    {
        path: 'info',
        component: InfoComponent,
        canActivate: []
    },
    {
        path: 'help',
        component: HelpComponent,
        canActivate: []
    },
    {
        path: 'geneology',
        component: GeneologyComponent,
        canActivate: []
    },
    {
        path: 'search',
        component: SearchComponent,
        canActivate: []
    },
    {
        path: 'acknowledgements',
        component: AcknowledgementsComponent,
        canActivate: []
    },
    {
        path: 'terms',
        component: TermsComponent,
        canActivate: []
    },
    {
        path: 'contact',
        component: ContactComponent,
        canActivate: []
    },
    {
        path: 'resource/:resource_id',
        component: ResourceComponent,
        canActivate: []
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        component: SplashComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class RoutingModule { }
