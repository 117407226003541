export class CachedValue {

    expirey: number;
    value: string;

    constructor (data, expirey?) {
        this.value = typeof data !== 'undefined' ? data : {};
        // use the provided expirey date or set it to 60 minutes if none exists
        this.expirey = typeof expirey !== 'undefined' ? expirey : (new Date()).getTime() + 3600000;
    }

    isCurrent(): boolean {
        return this.expirey > (new Date()).getTime();
    }
}
