import { Injectable } from '@angular/core';

@Injectable()
export class Storage {

    constructor() {

    }

    get(key): Promise<any> {
        return new Promise(resolve => {
            resolve(JSON.parse(localStorage.getItem(key)));
        });
    }

    set(key, value): Promise<any> {
        return new Promise(resolve => {
            localStorage.setItem(key, JSON.stringify(value));
            resolve();
        });
    }

    remove(key): Promise<any> {
        return new Promise(resolve => {
            localStorage.removeItem(key);
            resolve();
        });
    }

}
